export default onBeforeRoute

import { redirect } from 'vike/abort'
import { isProduction } from '../config/AppConfig'

const TenantRegex = /^\/:([^\/]+)/

// TODO: somehow load languages from orgs
const TenantLangHack = {
  'fi': 'fi',
  'uk': 'uk'
}

function onBeforeRoute(pageContext) {
  const { pathWithoutTenant, tenant, langCode } = extractTenant(pageContext.urlOriginal)

  return {
    pageContext: {
      tenant,
      langCode,
      urlLogical: pathWithoutTenant
    }
  }
}


function extractTenant(path) {
  const result = path.match(TenantRegex)

  if (result) {
    const tenant = result[1]

    return {
      tenant,
      pathWithoutTenant: path.substr(2 + tenant.length) || '/',
      langCode: TenantLangHack[tenant] || 'en'
    }

  } else {

    return {
      tenant: null,
      pathWithoutTenant: path,
      langCode: 'en'
    }
  }
}
